import React from 'react'
import { Link as GatsbyLink, StaticQuery, graphql } from 'gatsby'
import { Text, Box, NavLink as NavLinkBase } from 'rebass'
import styled, { injectGlobal } from 'styled-components'
import { keySort } from './../utils/keySort.js'
import sun from './../images/svgs/sun.svg'

const Navigation = styled(Box)`
  transition: all 0.5s ease;
`

const NavLink = styled(NavLinkBase)`
  &.active {
    color: ${props => props.theme.colors.trueBlue};

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 10px;
      background: url(${sun});
      background-size: 100%;
      background-position: center;
      transform: translate(calc(-100% - 12px), 0);
    }
  }

  &.isSubMenu {
    position: relative;
    display: block;
    width: calc(100% - 27px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      top: 50%;
      transform: translate(calc(-100% - 12px), -50%);
    }
  }

  &:hover {
    color: ${props => props.theme.colors.trueBlue};
  }
`

const ToggleSubmenu = styled(Box)`
  position: absolute;
  top: 8px;
  right: 1px;
  padding: 0 7px;
  transform: translate(-50%, 0);
  cursor: pointer;

  svg {
    width: 10px;
    pointer-events: none;

    rect {
      transition: all 0.2s ease;

      &:nth-child(1) {
        transform-origin: center;
        transform: scale(0);
      }
    }
  }

  &:hover {
    svg {
      rect {
        fill: ${props => props.theme.colors.trueBlue};

        &:nth-child(1) {
          transform: scale(1);
        }
      }
    }
  }
`

const SubMenu = styled(Box)`
  cursor: pointer;
  height: 100%;
  transition: all 0.5s;
  overflow: hidden;
  width: 100%;

  &.hide-me {
    max-height: 0 !important;

    /*
      I need to fix this one!!!
      As it's before in the DOM, this cannot work anymore
    */
    ~ div {
      svg {
        rect {
          &:nth-child(1) {
            transform: rotate(0);
          }
        }
      }
    }
  }
`

injectGlobal`
  .hide-me {
    div {
      max-height: 0 !important;
    }

    .toggleMenu {
      svg {
        rect {
          &:nth-child(1) {
            transform: scale(1);
          }
        }
      }

      &:hover {
        svg {
          rect {
            &:nth-child(1) {
              transform: scale(0);
            }
          }
        }
      }
    }
  }
`

const showNextSiblings = e => {
  const parentEl = e.target.parentElement
  const submenu = e.target.nextSibling

  if (!submenu.getAttribute('style')) {
    submenu.setAttribute('style', `max-height:${submenu.offsetHeight}px`)
  }

  if (!submenu.getAttribute('style')) {
    submenu.setAttribute('style', `max-height:${submenu.offsetHeight}px`)
  }

  !parentEl.classList.contains('hide-me')
    ? submenu.setAttribute('style', `max-height:${submenu.offsetHeight}px`)
    : null

  parentEl.classList.toggle('hide-me')
}

const Menu = ({ data }) => {
  const d = data.allContentfulMenuMain.edges[0].node.items

  return (
    <Box mb={[20, 0]}>
      <Text
        mb={[22, 0]}
        color="trueBlue"
        css={`
        text-decoration: underline
          cursor: pointer;

          @media (min-width: 768px) {
            display: none;
          }
        `}
        onClick={showNextSiblings}
      >
        Toggle navigation
      </Text>

      <Navigation id="navigation">
        {d.map((k, i) => {
          let childrens = k.page || []
          let Subnavigation = ''

          if (childrens.length > 0) {
            console.log(childrens)
            childrens = childrens.keySort('title')

            const sortedChildrens = childrens.sort(function(a, b) {
              return parseInt(a.title) > parseInt(b.title)
            })

            Subnavigation = (
              <React.Fragment>
                <ToggleSubmenu
                  is="span"
                  className="toggleMenu"
                  onClick={showNextSiblings}
                >
                  <svg
                    width="21px"
                    height="20px"
                    viewBox="0 0 21 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      id="Rectangle"
                      x="7.70406412"
                      y="0"
                      width="4.57533233"
                      height="20"
                    />
                    <rect
                      id="Rectangle"
                      transform="translate(9.991730, 10.000000) rotate(-90.000000) translate(-9.991730, -10.000000) "
                      x="7.70406412"
                      y="0"
                      width="4.57533233"
                      height="20"
                    />
                  </svg>
                </ToggleSubmenu>
                <SubMenu>
                  {sortedChildrens.map((l, j) => {
                    return (
                      <NavLink
                        key={l.id}
                        is={GatsbyLink}
                        to={l.slug}
                        title={l.title}
                        width={[1, 'calc(100% - 27px)']}
                        pl={[28]}
                        fontSize={[0, 1]}
                        fontWeight="normal"
                        className="isSubMenu"
                        activeClassName="active"
                      >
                        {l.title}
                      </NavLink>
                    )
                  })}
                </SubMenu>
              </React.Fragment>
            )
          }

          return (
            <Box
              key={k.id}
              style={{ position: 'relative' }}
              bg="white"
              css={``}
            >
              <NavLink
                is={GatsbyLink}
                to={k.slug}
                title={k.title}
                width={[1]}
                pl="0"
                py={[10]}
                fontSize={[0, 1]}
                fontWeight="bold"
                activeClassName="active"
              >
                {k.title}
              </NavLink>

              {Subnavigation}
            </Box>
          )
        })}
      </Navigation>
    </Box>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulMenuMain {
          edges {
            node {
              id
              title
              items {
                id
                title
                slug
                page {
                  id
                  title
                  slug
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} {...props} />}
  />
)
