import React, { createElement } from 'react'
import { graphql } from 'gatsby'

import { Box, Heading, Text, Image, Link } from 'rebass'
import marksy from 'marksy/jsx'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Breadcrumb from '../components/Breadcrumb'
import ArticleFooter from '../components/ArticleFooter'
import YoutubeEmbed from '../components/YoutubeEmbed'
import SecretBlock from '../components/SecretBlock'
import { fetchPreview } from '../utils/identity.js'
import filterIframe from '../utils/filterIframe.js'

const compile = marksy({
  createElement,
  components: {
    Youtube: YoutubeEmbed,
    SecretBlock,
  },
  elements: {
    h2({ children }) {
      return (
        <Heading
          is="h2"
          fontSize={[1, 3]}
          fontWeight="normal"
          mt={['28px', '36px']}
          mb={['18px', '26px']}
        >
          {children}
        </Heading>
      )
    },
    h3({ children }) {
      return (
        <Heading
          is="h3"
          fontSize={[1, 2]}
          fontWeight="normal"
          mt={['20px', '28px']}
          mb={['10px', '18px']}
        >
          {children}
        </Heading>
      )
    },
    p({ children }) {
      return (
        <Text
          is="p"
          fontSize={[0, 1]}
          mb={[1]}
          css={`
            line-height: 1.57;
          `}
        >
          {children}
        </Text>
      )
    },
    ul({ children }) {
      return (
        <Box is="ul" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    ol({ children }) {
      return (
        <Box is="ol" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    li({ children }) {
      return (
        <Text
          is="li"
          fontSize={[0, 1]}
          mb={[1, 8]}
          style={{
            listStyle: 'circle',
          }}
        >
          {children}
        </Text>
      )
    },
    a({ href, title, target, children }) {
      return (
        <Link
          href={href}
          alt={title}
          title={title}
          target={target}
          color="trueBlue"
        >
          {children}
        </Link>
      )
    },
    blockquote({ children }) {
      return (
        <Text
          is="blockquote"
          className="blockquote"
          css={`
            p {
              font-size: 35px;
              line-height: 1.46;
              text-align: center;
              margin-top: 82px;
              margin-bottom: 76px;
              color: #0019c5;
              @media (max-width: 767px) {
                font-size: 18px;
                margin: 40px 0;
              }
            }
          `}
        >
          {children}
        </Text>
      )
    },
    img({ src, alt }) {
      // as it's only those 3 formats, it's pretty straigthforward, but we could use whatever number of formats
      const regex = /.mp4|.ogg|.webm/g
      const isVideo = src.match(regex)

      if (isVideo) {
        return (
          <React.Fragment>
            <Box
              is="video"
              src={src}
              alt={alt}
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
              controls
            >
              <source src={src} />
            </Box>
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Image
              src={src}
              alt={alt}
              style={{
                width: '100%',
                maxWidth: '100%,',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
            />
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      }
    },
  },
})

class PageTemplate extends React.Component {
  state = {
    isQueryDone: false,
    querData: null,
  }
  tryGetNewData() {
    this.setState({ isQueryDone: false })
    fetchPreview(this.props.data.page.entryID)
      .then(res => {
        const output = { ...this.props.data }
        output.page.body.body = res.fields.body
        this.setState({
          isQueryDone: true,
          queryData: output,
        })
      })
      .catch(err => {
        console.log(err.message)
      })
  }
  componentDidMount() {
    this.tryGetNewData()
  }
  componentDidUpdate(pp, ps) {
    if (pp.data !== this.props.data) {
      this.tryGetNewData()
    }
  }
  render() {
    return PageTemplateRender({
      ...this.props,
      data: this.state.isQueryDone ? this.state.queryData : this.props.data,
    })
  }
}

const PageTemplateRender = ({ data, pageContext }) => {
  const d = data.page
  const compiled = compile(d.body ? filterIframe(d.body.body) : '')
  return (
    <Layout>
      <SEO title={d.title} pageUrl={d.slug} />
      <Box is="article">
        <Breadcrumb />
        <Box>
          <Heading is="h1" fontSize={[2, 5]} fontWeight="normal" mb="14px">
            {d.title}
          </Heading>
          <Box>{compiled ? compiled.tree : 'ERROR FETCHING DATA'}</Box>
        </Box>
        <ArticleFooter
          author={
            d.author
              ? {
                  name: `${d.author.name} - ${d.author.occupation}`,
                  avatar: d.author.avatar.fixed.src,
                  url: `mailto:${d.author.email}`,
                }
              : null
          }
          previousArticle={pageContext.previous}
          nextArticle={pageContext.next}
        />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      entryID: contentful_id
      title
      slug
      author {
        name
        occupation
        avatar {
          fixed(width: 72, quality: 100) {
            src
          }
        }
        email
      }
      body {
        body(remove_secret: true)
      }
    }
  }
`

export default PageTemplate
